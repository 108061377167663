<template>
  <main class="project">
    <div class="header" v-rellax="{speed: -12}"
         :style="{'background-image': 'url(' + require('@/assets/img/project/header.jpg') + ')'}">

    </div>
    <section class="testimony">
      <div class="container">
        <div class="row text-center title" data-aos="fade-up">
          <div class="col-xs-12">
            <h1>A testimony from</h1>
          </div>
        </div>
        <div class="row" data-aos="fade-up">
          <div class="col-xs-12">
            <div class="anna">
              <img :src="require('@/assets/img/home/Anna_Iluridze.png')" alt="Anna Iluridze">
              <h3 class="name">Anna Iluridze</h3>
              <span class="otherjob">Gender expert and researcher</span>
              <span class="job">Head of Gender Equality Department at the Office of Public Defender of Georgia</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">
            <p data-aos="fade-up" class="justify">
              Through the stories told by visual and narrative media, women from different backgrounds, age groups and
              geographical locations discuss and explore new ways in which feminism affects their lives. The project
              successfully unfolds both the celebration of the changing social and economic shifts in post soviet
              Georgia, while also provides us with a platform through which to consider how these very changes have led
              to complex and contradictory constraints.</p>
            <p data-aos="fade-up" class="justify">This project is telling the story how western feminist theory has
              travelled to
              Georgia, why this theory matters for Georgian women and how the theory and women themselves transformed as
              a result. The photos focus not only on the bodily manifestations and their individually ascribed
              archetype, but also give the narrative ‘realism’ of each character. The Authors rightly address the fact
              that in many ways the contradictory nature of living in the country charged with conflicts, neo-liberal
              development agenda and Soviet legacy is such that it both ridicules and celebrates feminism as such.</p>
            <p data-aos="fade-up" class="justify">The project does not speak for women, nor it tries to reformulate or
              interpret the
              narrations. On the contrary, it lets you dive into the grounded, unedited, often contradictory stories of
              women’s different interaction with feminism and social change and this allows different visions of
              Country’s past, present and future.</p>
            <p data-aos="fade-up" class="justify">The modern history of feminism in Georgia is not easy to unfold. It
              stems from the
              clash of different empires and imperialisms, goes through the soviet idea of women empowerment and rests
              onto the “normative idea of the “West” (Barkaia, Waterston, 2017) characterizing the post-soviet thinking.
              “The West as the norm” has brought the western feminist progress narratives as an essential part of the
              progress and western culture. Later, in 2000’s, these narratives enabled discussion about the sex / gender
              distinction and the identities came to be understood to be fixed in more than one position. Later,
              feminist theory also unfolded that no identity and/or subject is fixed and queer subjects started to argue
              that even in Georgia, every aspect of the identity or sexuality can be contested, examined and changed,
              sametimes marking the previous understandings (of feminist theory) as limiting, and simplistic. </p>
            <p data-aos="fade-up" class="justify">Here, I’d like to go back to do some of the more complicated
              exercises, and
              deconstruct different narrative forms representing women in the project. Firstly, I’d like to outline that
              feminism, in any form, brings joy and independence to the project participants. As pathetic as this may
              sound, there is always excitement and success in their narrative style. Telling feminist stories, in one
              way or another, has enabled them to be positioned in a society as dynamic, interesting and sensual
              subjects. </p>
            <p data-aos="fade-up" class="justify">
              Second, the narratives often do not have clear chronology: what is the past and how this past has affected
              key shifts in their lives and their feminist present heavily depends on political and cultural context
              they live in. The project successfully unfolds the multiplicity, instability and difference between living
              in the urban and rural areas, closer to conflict zones, living in Georgia while being ethnically
              non-georgian, a teenager, a mother, an activist. These multiplicities are enacted through the use of terms
              describing their everyday feminisms as “interesting”, “painful”, “difficult”, complex” and so on.
            </p>
            <p data-aos="fade-up" class="justify">Third, these differences are translated into multiple understanding of
              what feminism
              represents, assumptions about what is lacking and determines their belonging to different groups from The
              Nation to the “Space for female talk”, their relationship with “patriarchy”, “womanhood”, “female
              subordination” and the intersections with ethnicity, gender, sexuality and class. </p>
            <p data-aos="fade-up" class="justify">Understandably, given the priority given to heterosexulity in Georgian
              culture, the
              project concentrates more on heterosexuality than on other sexualities. Yet, it does not ignore sexual
              diversity and opens a space for discussion. Feminist insight on sexuality and pleasure is included but not
              central and represents itself through the issues of power and how this power is connected to the living in
              the neo-liberal, post-conflict Georgia, involving distorted ideas about the female sexuality, or the
              absence of it. </p>
            <p data-aos="fade-up" class="justify"></p>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<script>

export default {
  name: 'Project',

  data () {
    return {}
  },
  components: {},
  computed: {},
  methods: {},
  created () {

  }
}
</script>


<style lang="scss" scoped>

  body {
    transform-style: preserve-3d;
  }


  main.project {

    .header {
      background-position: center;
      background-size: cover;
      height: 100vh;
      position: relative;
      z-index: 0;
    }

    section {
      padding: 8rem 0;
      z-index: 1;
      position: relative;
    }
  }


  .project-block {
  }

  section.context {
    background: white;
  }


  section.testimony {
    padding: 10rem 0;
    background: white;

    .anna {
      text-align: center;
      max-width: 400px;
      margin: 0 auto 4rem;

      img {
        width: 250px;
      }

      .name {
        font-size: 3rem;
        margin-top: 2rem;
        margin-bottom: .5rem;
      }

      span {
        display: block;
        font-family: 'Couture', Helvetica, Arial, sans-serif;
      }

      .otherjob {
        font-size: 1.4rem;
        line-height: 1.5rem;
        color: $green;
        margin-bottom: 2rem;
      }

      .job {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: rgb(black, .45)
      }

    }
  }

</style>
